import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import heroImg from "../images/hero-image.jpg"

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <div className="p-10 border-b border-black">
        <h1 className="font-CyGroteskGrandDemi uppercase mb-10">About</h1>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-10">
          <div className="">
            <img src={heroImg} className="w-full" />
          </div>
          <div className="">
            <p>Are you on the hunt for the perfect travel duffle bag that combines style, functionality, and durability? Look no further! Our comprehensive blog is your go-to resource for all things travel duffle bags and similar products. Dive into a treasure trove of information as we provide in-depth reviews, detailed product comparisons, and expert tips and recommendations.</p>
            <p>Discover a wide range of travel duffle bags that have been meticulously tested and scrutinized, ensuring that only the best options make it to our list. Whether you're a frequent flyer or a weekend explorer, our guide will help you find the ideal bag to suit your specific travel needs.</p>
            <p>Learn about the latest innovations in duffle bag design, from smart compartments to waterproof materials, designed to make your journeys more convenient and hassle-free. Our team of travel enthusiasts and experts have curated a collection of premium travel duffle bags that strike the perfect balance between style and practicality.</p>
            <p>Say goodbye to travel woes and make packing a breeze with our carefully curated selection. Embark on your next adventure fully equipped with the best travel duffle bag that effortlessly accommodates your essentials. Explore our blog today and unlock the secrets to stress-free and enjoyable travel experiences.</p>
          </div>
         </div>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="About" />

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
